@use '@/styles/utils/mixins.scss' as *;

.dealersContainer {
  .dealersWrapper {
    position: relative;
    background: $kiaPolarWhite;
    display: flex;
    flex-direction: column;
    @include tab() {
      box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    }
    @media screen and (min-width: 992px) {
      position: absolute;
      z-index: 2;
      top: 0;
      height: 100%;
    }
    @media screen and (max-width: 991.98px) {
      position: absolute;
      z-index: 2;
      top: 0;
      height: 100%;
    }
    &.showDealerOnly {
      @media screen and (max-width: 991.98px) {
        top: initial;
        bottom: 0;
        height: auto;
      }
    }
    @media screen and (max-width: 767px) {
      position: fixed;
      top: initial;
      bottom: 0;
      height: 330px;
    }
    @media screen and (max-width: 576px) {
      height: 180px;
    }
    @media screen and (max-width: 480px) {
      height: 220px;
    }
    .hideSearch {
      display: none;
    }
  }
}

.container {
  position: relative;
  height: 700px;

  .visuallyHidden {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
  }
}
