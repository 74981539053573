@use '@/styles/utils/vars.scss' as *;

.searchDealers {
  width: 90% !important;
  margin: 20px 20px;
  padding-left: 0 !important;
  border: 0px !important;
  border-bottom: 1px solid $keyline !important;
  border-radius: 0 !important;
  @media screen and (max-width: 576px) {
    margin: 10px 20px 0px;
  }

  :global {
    .ant-input {
      font-size: 16px !important;
    }
  }
  &:focus,
  &:focus-within {
    box-shadow: none !important;
  }
  .inputIcon {
    font-size: 20px;
    margin-right: 10px;
  }
}
