@use '@/styles/utils/vars.scss' as *;

.dealersListWrapper {
  .dealerItem {
    cursor: pointer;
    border-bottom: 1px solid $keyline;
    padding: 15px;
    @media screen and (min-width: 992px) {
      padding-left: 0;
    }

    &:first-child {
      margin-top: 0;
    }
    &:last-child {
      border-bottom: none;
    }
  }
  .selectedDealerItem {
    background: $selectedOption;
    border-bottom: none;
    transition: 0.4s;
  }
  .dealerNameWrapper {
    display: flex;
    align-items: center;
    .locationName {
      display: flex;
      align-items: center;
      font-size: 12px;
      color: $dealerStreetColor;
      width: 100%;
    }
    .arrowIcon {
      margin-right: 5px;
    }
    .dealerIcon {
      width: 18px;
      height: 18px;
    }
    .dealerName {
      font-size: 16px;
      color: $kiaMidnightBlack;
      font-weight: 600;
      padding-left: 5px;
    }
  }

  .dealerStreet {
    padding-left: 26px;
    margin-top: 10px;
    font-size: 14px;
    font-weight: 400;
    color: $dealerStreetColor;
  }
  .dealerPhone {
    padding-left: 26px;
    margin-top: 5px;
    color: $kiaMidnightBlack;
    font-size: 14px;
    font-weight: 600;
    a:hover,
    a:active {
      color: $kiaLiveRed;
    }
  }
  .dealerWorkHours {
    padding-left: 26px;
    margin-top: 5px;
    font-size: 14px;
    font-weight: 400;
    color: $kiaMidnightBlack;
    .openedHours {
      color: $workTimeColor;
      font-weight: 600;
    }
    .closedHours {
      color: $kiaLiveRed;
      font-weight: 600;
    }
  }
  .dealerDetailsWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media screen and (max-width: 576px) {
      align-items: start;
    }
  }
  .detailsButton {
    background: $kiaMidnightBlack;
    color: $kiaPolarWhite;
    border-radius: 25px;
    padding: 6px 12px 4px;
    font-size: 12px;
    display: flex;
    align-items: center;
    font-weight: bold;
    margin-top: 5px;
  }
  .arrowRightIcon {
    height: 11px;
    width: 5px;
    margin-left: 10px;
  }
  .dealersNoResults {
    text-align: center;
  }
  &.dealersListWrapperHeight {
    height: max-content;
    padding-left: 0;
    padding-right: 0;
    background: #f1f1f1;

    @media screen and (max-width: 767px) {
      min-height: 160px;
    }
    @media screen and (max-width: 576px) {
      min-height: 180px;
    }
    @media screen and (max-width: 320px) {
      min-height: 200px;
    }
  }
}

.mobileDealerCardClose {
  margin-left: auto;

  @media screen and (min-width: 991px) {
    display: none;
  }
}
